<template>
  <div class="checkschools">
    <div class="minw">
          <searchSchool activeId="0"></searchSchool>
    </div>
  </div>
</template>

<script>

import searchSchool from "@/components/searchSchool.vue"
export default {
  name: "checkschools",
  components: {
    searchSchool
  },
  props: {},
  data() {
    return {



    };
  },
  computed: {},
  watch: {},
  created() {

  },
  mounted() {
  },
  methods: {

  },
};
</script>

<style scoped lang="scss">
 </style>
